#raffle-group-early-bird-grand-launch-unlock-value {
	> strong {
		color: #fff8c2;
		font-family: 'Dokdo';
		font-size: 18px;
	}
	@media (min-width: 768px) {
		> strong {
			font-size: 25px;
		}
	}

	@media (min-width: 1280px) {
		> strong {
			font-size: 20px;
		}
	}
}
