/* Core functionality */
.marquee {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	overflow: hidden;
	background-image: linear-gradient(
		to bottom,
		rgba(223, 230, 237, 0.5),
		rgba(255, 255, 255, 0.7)
	);
}
.marquee > span {
	white-space: nowrap;
	animation: marquee 5s linear infinite;
	max-width: none;
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

.signup-hero > strong {
	color: #397ebf;
}
