#raffle-view-as {
	&.mantine-Select-input {
		background-color: #fafafa;
		border: none;
		color: #397ebf;
		font-weight: 600;
		font-size: 8px;
		padding: 10px 10px;
		height: 22px;
		min-height: auto;
		width: 90px;
	}

	@media (min-width: 768px) {
		&.mantine-Select-input {
			font-size: 12px;
			height: 40px;
			width: 130px;
		}
	}

	@media (min-width: 1280px) {
		&.mantine-Select-input {
			font-size: 14px;
		}
	}
}

.mantine-Select-dropdown {
	border: none;
	border-radius: 0.2rem;
	overflow: hidden;
}

#raffle-view-as-items {
	.mantine-Select-itemsWrapper,
	.mantine-Select-item {
		background-color: #fafafa;
		border: none;
	}

	.mantine-Select-item {
		color: #397ebf;
		font-weight: 600;
		font-size: 8px;
	}

	@media (min-width: 768px) {
		.mantine-Select-item {
			font-size: 12px;
		}
	}

	@media (min-width: 1280px) {
		.mantine-Select-item {
			font-size: 14px;
		}
	}
}

#raffles-scroll-area {
	.mantine-ScrollArea-viewport {
		padding-bottom: 20px;
	}
}

#explore-more-image {
	.mantine-Image-imageWrapper {
		height: 88px;

		@media (min-width: 768px) {
			& {
				height: 125px;
			}
		}

		@media (min-width: 1280px) {
			& {
				height: 210px;
			}
		}
	}
}

#raffle-details-prize-winner-image {
	figure,
	.mantine-Image-imageWrapper {
		height: 100%;
	}
}
