#raffle-details-unlock-value {
	> strong {
		color: #e5b144;
		font-family: 'Dokdo';
		font-size: 20px;
	}
	@media (min-width: 768px) {
		> strong {
			color: #e5b144;
			font-family: 'Dokdo';
			font-size: 25px;
		}
	}

	@media (min-width: 1280px) {
		> strong {
			color: #e5b144;
			font-family: 'Dokdo';
			font-size: 30px;
		}
	}
}

#raffle-details-breadcrumb {
	.mantine-Breadcrumbs-separator {
		color: #397ebf;
	}
}
